var DomusVet = {
    toggleServicos: function(){
        var that = this;
        var empresa  = document.querySelector('#cardEmpresa');
        var servicos = document.querySelector('#cardServicos');
        var restante = document.querySelector('#cardServicosRestante');
        var botao = document.querySelector('#cardToggle');

        var responsive = window.innerWidth < 751;


        if(!that.visivel){
            // Esconde o texto da empresa
            empresa.style.opacity = 0.5;
            empresa.style.marginLeft = '-150%';
            servicos.style.width = '100%';
            servicos.style.flexBasis = '100%';
            restante.style.display = 'block';
            restante.style.opacity = 1;
            that.visivel = true;
            botao.innerText = 'esconder'
        }else{
            // Esconde o texto da empresa
            empresa.style.opacity = 1;
            empresa.style.marginLeft = 0;
            servicos.style.width = '48%';
            servicos.style.flexBasis = '48%';
            restante.style.display = 'none';
            restante.style.opacity = 0;
            that.visivel = false;
            botao.innerText = 'ler mais'
        }

        if(responsive){
            empresa.style.marginLeft = '0';
            servicos.style.width = '100%';
            servicos.style.flexBasis = '100%';
            empresa.style.opacity = 1;
            return false;
        }

    },
    togglePrivacidadeSeguranca: function(){
        var veu = document.querySelector('.veu');
        if(veu.classList.contains('veuVisivel')){
            veu.classList.remove('veuVisivel');
        }else{
            veu.classList.add('veuVisivel');
        }
    },
    visivel: false
};

var timeoutObject;

window.addEventListener('resize', function(){
    clearTimeout(timeoutObject);
   timeoutObject = setTimeout(function(){
       DomusVet.toggleServicos();
       DomusVet.visivel = true;
   }, 500);
});
